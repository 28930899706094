
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { GetWxRoleList } from '@/service/main/main'
import { infoRules } from '../config/infoRules'
export default defineComponent({
  emits: ['nextStepSend'],
  async setup(props, { emit }) {
    const formEle = ref()
    onMounted(() => formEle)
    const basicInfo = reactive({
      name: '',
      anchorName: '',
      anchorWechat: '',
      subAnchorWechat: '',
      createrWechat: '',
      time: [],
      // 直播间默认手机直播
      type: 0,
      isFeedsPublic: 1,
    })

    // 获取用户选择数据
    let memberList = []
    let getRoleListRes = await GetWxRoleList()
    memberList = getRoleListRes.Data
    console.log(memberList)
    const pickerOptions = {
      disabledDate: (date: any) => {
        return date.getTime() < Date.now() - 24 * 60 * 60 * 1000
      },
    }
    const nextStep = () => {
      formEle.value.validate((valid: boolean) => {
        if (valid) {
          emit('nextStepSend', basicInfo)
        }
      })
    }
    return {
      infoRules,
      basicInfo,
      getRoleListRes,
      memberList,
      pickerOptions,
      formEle,
      nextStep,
    }
  },
})
