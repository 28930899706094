const infoRules = {
  name: [
    {
      required: true,
      message: '请输入直播间标题',
      trigger: 'blur',
    },
  ],
  subAnchorWechat: [
    {
      required: false,
      message: '请输入副微信号',
      trigger: 'blur',
    },
  ],
  time: [
    {
      required: true,
      message: '请选择直播时间',
      trigger: 'blur',
    },
  ],
  anchorName: [
    {
      required: true,
      message: '请输入主播昵称',
      trigger: 'blur',
    },
  ],
  anchorWechat: [
    {
      required: true,
      message: '请选择直播微信',
      trigger: 'blur',
    },
  ],
}

export { infoRules }
