
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { MainHeader } from '@/components/main-header/index'
import { RoomCreate } from '@/service/main/main'
import { HyUpload } from '@/base-ui/upload/index'
import { IFormItem } from '@/base-ui/form/index'
import InforMation from './cpns/InforMation.vue'
import { infoRules } from './config/infoRules'
import router from '@/router'
export default defineComponent({
  components: { MainHeader, HyUpload, InforMation },
  async setup() {
    const formEle = ref()
    let dialogImageUrl1 =
      'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png'

    let dialogImageUrl2 =
      'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png'

    let dialogImageUrl3 =
      'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png'

    let basicInfo = reactive({
      name: '',
      anchorName: '',
      coverImg: '',
      shareImg: '',
      feedsImg: '',
      anchorWechat: '',
      subAnchorWechat: '',
      createrWechat: '',
      time: [],
      // 直播间默认手机直播
      type: 0,
      isFeedsPublic: 1,
    })
    onMounted(() => {
      // console.log(formEle, 'formEle')
      return formEle
    })

    let step = ref(1)
    // 表单验证通过跳转下一步
    const nextStepSend = (param: any) => {
      step.value = 2
      console.log(param)
      basicInfo.name = param.name
      basicInfo.anchorName = param.anchorName
      basicInfo.anchorWechat = param.anchorWechat
      basicInfo.createrWechat = param.createrWechat
      basicInfo.isFeedsPublic = param.isFeedsPublic
      basicInfo.subAnchorWechat = param.subAnchorWechat
      basicInfo.time = param.time
    }

    // // 获取用户选择数据
    // let memberList = ref(null)
    // let getRoleListRes = GetWxRoleList()
    // memberList = await getRoleListRes

    const formItems: IFormItem[] = [
      {
        type: 'input',
        label: '直播间标题',
        placeholder: '填写本次直播的标题',
        vModel: 'name',
      },
      {
        type: 'input',
        label: '主播副微信号',
        placeholder: '主播副微信号',
        vModel: 'subAnchorWechat',
      },
      {
        type: 'datepicker',
        label: '开播时间',
        placeholder: '请输入时间范围',
        vModel: 'time',
        otherOptions: {
          startPlaceholder: '开始时间',
          endPlaceholder: '结束时间',
          type: 'daterange',
        },
      },
      {
        type: 'input',
        label: '主播昵称',
        placeholder: '填写本次直播的昵称',
        vModel: 'anchorName',
      },
      {
        type: 'select',
        label: '直播微信号',
        placeholder: '请输入运动',
        vModel: 'anchorWechat',
        options: [
          {
            title: '篮球',
            value: 'basketball',
          },
          {
            title: '足球',
            value: 'foottball',
          },
        ],
      },
    ]

    let liveAbility = reactive({
      closeComment: false,
      closeGoods: false,
      closeShare: false,
      closeLike: false,
    })
    let extendAbility = reactive({
      closeReplay: false,
      closeKf: false,
    })

    const upload1 = (coverImg: any) => {
      // console.log(coverImg)
      // dialogImageUrl1 = coverImg.value
      basicInfo.coverImg = coverImg.value
    }
    const upload2 = (shareImg: any) => {
      // console.log(shareImg)
      // dialogImageUrl2 = shareImg.value
      basicInfo.shareImg = shareImg.value
    }
    const upload3 = (feedsImg: any) => {
      // console.log(feedsImg)
      // dialogImageUrl3 = feedsImg.value
      basicInfo.feedsImg = feedsImg.value
    }

    // 创建直播间
    const establish = async () => {
      let create = {
        name: basicInfo.name, //直播间标题
        coverImg: basicInfo.coverImg,
        startTime: basicInfo.time[0] / 1000,
        endTime: basicInfo.time[1] / 1000,
        anchorName: basicInfo.anchorName,
        anchorWechat: basicInfo.anchorWechat,
        subAnchorWechat: basicInfo.subAnchorWechat,
        createrWechat: basicInfo.createrWechat,
        shareImg: basicInfo.shareImg,
        feedsImg: basicInfo.feedsImg,
        isFeedsPublic: basicInfo.isFeedsPublic,
        type: basicInfo.type,
        closeLike: liveAbility.closeLike ? 0 : 1,
        closeGoods: liveAbility.closeGoods ? 0 : 1,
        closeComment: liveAbility.closeComment ? 0 : 1,
        closeReplay: extendAbility.closeReplay ? 0 : 1,
        closeShare: liveAbility.closeShare ? 0 : 1,
        closeKf: 1,
      }

      const result = await RoomCreate(create)
      console.log(result)
      if (result.errcode == 0) {
        router.push('./live')
      }
    }
    return {
      step,
      formEle,
      liveAbility,
      extendAbility,
      formItems,
      infoRules,
      nextStepSend,
      establish,
      upload1,
      upload2,
      upload3,
      dialogImageUrl1,
      dialogImageUrl2,
      dialogImageUrl3,
    }
  },
})
